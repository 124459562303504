import _ from 'lodash'
import { $W, SdkInstance, Connection, PlatformLogger } from '@wix/thunderbolt-symbols'
import { SdkFactoryParams } from './createSdkFactoryParams'
import { IControllerEvents } from './ControllerEvents'
import { CompCacheParams, InstanceCacheFactory } from './instanceCache'
import { ComponentSdksManager } from './componentSdksLoader'
import { BootstrapData } from '../types'
import { Model } from './types'
import instancesObjectFactory from './instancesFactory'

export interface WixSelector {
	create$w: (controllerCompId: string) => $W
	getInstance: any
	$wFactory: (getInstancesForRole: (role: string, findOnlyNestedComponents: boolean) => Array<SdkInstance>, controllerId: string) => $W
	flushOnReadyCallbacks: () => Promise<any>
}

export default function({
	models,
	getSdkFactoryParams,
	controllerEventsFactory,
	sdkInstancesCache,
	componentSdksManager,
	logger,
	bootstrapData
}: {
	models: Model
	getSdkFactoryParams: SdkFactoryParams['getSdkFactoryParams']
	controllerEventsFactory: IControllerEvents
	sdkInstancesCache: InstanceCacheFactory
	componentSdksManager: ComponentSdksManager
	logger: PlatformLogger
	bootstrapData: BootstrapData
}) {
	const onReadyCallbacks: { [controllerCompId: string]: Array<Function> } = {}

	const invokeControllerOnReady = (controllerCompId: string) => {
		// It's possible to have a controller without an onReady Callback, for example wix code without any $w.onReady().
		if (!onReadyCallbacks[controllerCompId]) {
			return Promise.resolve()
		}

		return onReadyCallbacks[controllerCompId].map((onReady) => onReady())
	}

	const flushOnReadyCallbacks = async () => {
		await componentSdksManager.waitForSdksToLoad()
		return Promise.all(_.flatMap(models.platformModel.orderedControllers, invokeControllerOnReady))
	}

	const getChildrenFn = (compId: string, controllerCompId: string) => {
		const compIdConnections = models.platformModel.compIdConnections
		const containersChildrenIds = models.platformModel.containersChildrenIds
		return () => {
			const childrenIds = containersChildrenIds[compId] || []
			return _.map(childrenIds, (id: string) => {
				const connection = _.get(compIdConnections, [id, controllerCompId])

				return getInstance({
					controllerCompId,
					compId: id,
					compType: models.structureModel[id].componentType,
					role: _.get(connection, 'role', ''),
					connection
				})
			})
		}
	}

	function getInstance({
		controllerCompId,
		compId,
		compType,
		role,
		connection
	}: {
		controllerCompId: string
		compId: string
		compType: string
		role: string
		connection?: Connection
	}): SdkInstance | Array<SdkInstance> | null {
		const compCacheParams: CompCacheParams = {
			controllerCompId,
			compId,
			role
		}
		const instanceFromCache = sdkInstancesCache.getSdkInstance(compCacheParams)
		if (instanceFromCache) {
			return instanceFromCache
		}

		const componentSdkFactory = componentSdksManager.getComponentSdkFactory(compType, { compId, role, controllerCompId })
		if (!componentSdkFactory) {
			return {}
		}
		const sdkFactoryParams = getSdkFactoryParams({
			compId,
			controllerCompId,
			getChildren: getChildrenFn(compId, controllerCompId),
			connection,
			compType,
			role,
			getInstance
		})
		const instance = componentSdkFactory(sdkFactoryParams)
		sdkInstancesCache.setSdkInstance(compCacheParams, instance)
		// TODO: remove this when editor-elements expose mixins to Thunderbolt (baseProps)
		_.assign(instance, {
			connectionConfig: connection?.config,
			uniqueId: compId,
			id: role
		})
		return instance
	}

	function queueOnReadyCallback(onReadyCallback: () => Promise<any>, controllerId: string) {
		onReadyCallbacks[controllerId] = onReadyCallbacks[controllerId] || []
		onReadyCallbacks[controllerId].push(onReadyCallback)
	}

	function $wFactory(getInstancesForRole: (role: string, findOnlyNestedComponents: boolean) => Array<SdkInstance>, controllerId: string): $W {
		function wixSelectorInternal(selector: string, findOnlyNestedComponents: boolean = false) {
			if (selector === 'Document') {
				const DocumentSdkFactory = componentSdksManager.getComponentSdkFactory('Document', { compId: 'Document', controllerCompId: controllerId, role: 'Document' })
				if (!DocumentSdkFactory) {
					return
				}
				return DocumentSdkFactory(
					getSdkFactoryParams({
						compId: controllerId,
						controllerCompId: controllerId,
						getChildren: getChildrenFn(controllerId, controllerId),
						compType: 'Document',
						role: 'Document',
						getInstance
					})
				)
			}
			const role = selector.slice(1)
			const instances = getInstancesForRole(role, findOnlyNestedComponents)
			if (!instances.length) {
				return []
			}
			if (_.first(selector) === '#') {
				return instances[0]
			}
			return instancesObjectFactory(instances)
		}
		const $w = (selector: string) => wixSelectorInternal(selector)
		const controllerEvents = controllerEventsFactory.createScopedControllerEvents(controllerId)
		$w.fireEvent = controllerEvents.fireEvent
		$w.off = controllerEvents.off
		$w.on = controllerEvents.on
		$w.once = controllerEvents.once
		$w.onReady = (cb: () => Promise<any>) => queueOnReadyCallback(cb, controllerId)
		$w.at = _.noop
		$w.createEvent = _.noop
		$w.onRender = _.noop
		$w.scoped = (selector: string) => wixSelectorInternal(selector, true)

		return $w as $W
	}

	const create$w: WixSelector['create$w'] = (controllerCompId) => {
		const getInstancesForRole = (role: string) => {
			const controllerConnectionsByRole = models.platformModel.connections[controllerCompId] || {} // controller might not have connections
			const connections = controllerConnectionsByRole[role] || []
			return connections.map((connection: Connection) => {
				const compId = connection.compId
				const compFromStructure = models.structureModel[compId]
				if (!compFromStructure) {
					logger.captureError(new Error('$w Error: could not find component in structure'), {
						extra: {
							controllerCompId,
							role,
							compId,
							structureModel: models.structureModel,
							connection,
							rawMasterPageStructure: models.rawMasterPageStructure,
							rawPageStructure: models.rawPageStructure,
							currentPageId: bootstrapData.currentPageId,
							currentContextId: bootstrapData.currentContextId
						}
					})
					return {}
				}
				const compType = compFromStructure.componentType
				return getInstance({
					controllerCompId,
					compId,
					connection,
					role,
					compType
				})
			})
		}
		return $wFactory(getInstancesForRole, controllerCompId)
	}

	return {
		create$w,
		getInstance,
		$wFactory,
		flushOnReadyCallbacks
	}
}
